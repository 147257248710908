export const defaultLayout = {
  isHomepage: false,
  hasAlert: false,
  hasHeader: true,
  hasGoHiltonHeader: false,
  hasFixedHeader: false,
  hasFooter: true,
  hasSearchWidget: true,
  forwardPageURI: null,
};

export const withoutSearchWidget = {
  ...defaultLayout,
  hasSearchWidget: false,
};

export const goHiltonLayout = {
  ...defaultLayout,
  hasGoHiltonHeader: true,
  hasSearchWidget: false,
};

export const minimalLayout = {
  ...defaultLayout,
  hasMinimalHeader: true,
  hasSearchWidget: false,
};
