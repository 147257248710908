import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormInput, FormLabel } from '@dx-ui/osc-form';
import { ActionDialog } from '@dx-ui/osc-dialog-v2';
import type { DateFormat } from '@dx-ui/utilities-dates';
import { useLocale, useDateFormat } from '@dx-ui/utilities-dates';
import { addDays, startOfDay } from 'date-fns';
import { useTranslation } from 'next-i18next';
import DatePickerDatesButton from './date-picker.dates-button';
import type { DatePickerCalendarProps } from './date-picker.calendar';
import { DatePickerCalendar } from './date-picker.calendar';
import cloneDeep from 'lodash/cloneDeep';
import { useIsClient } from 'usehooks-ts';

export type DatePickerProps = {
  dateFieldNamePrefix?: string;
  afterCalendarContent?: React.ReactNode;
  /** Removes "my dates are flexible" input */
  hideFlexDates?: boolean;
  /** Options for "When?" and "(Required)" label */
  labelOptions?: {
    whenLabel: boolean;
    requiredDates?: boolean;
  };
  hasFlexDatesDisabled?: boolean;
  children?: (props: { day?: DateFormat; endDay?: DateFormat }) => React.ReactNode;
  isOpen?: boolean;
  /** Callback invoked on confirm/done */
  onConfirm?: () => void;
  /** Callback invoked on modal open */
  onOpen?: () => void;
  /** Callback invoked on cancel/dismiss */
  onDismiss?: () => void;
  /** Callback invoked on reset dates */
  onResetDates?: () => void;
  /**
   * whether or not to set the default day of today. set to `false` if dates are optional
   */
  hasTodayAsDefault?: boolean;
  /** Callback invoked on my flexible dates click */
  onFlexibleDates?: () => void;
  //NHCSEARCH-5363 Flex date verbiage MVT
  flexDatesLabel?: string;
} & Omit<DatePickerCalendarProps, 'day' | 'dayFieldName' | 'endDay' | 'endDayFieldName'>;

export type DatesData = {
  flexDates: boolean;
  day?: Date;
  endDay?: Date;
};

/**
 * Use the `<DatePicker/>` component inside of the ShopForm in order to provide the OSC experience for
 * selecting arrival/departure dates. You can pass a custom button in as `children` and also use `renderProps`
 * to access the `day` and `endDay` for use in your custom button. The default button is `<DatePickerDatesButton/>`
 */
export const DatePicker = ({
  afterCalendarContent,
  MonthDateSelectorComponent,
  allowPrevious,
  allowSameDay = true,
  hasFlexDatesDisabled = false,
  isDayUse = false,
  dateFieldNamePrefix = 'dates',
  hideFlexDates,
  labelOptions,
  language,
  maxDays = 730,
  hasTodayAsDefault = true,
  enabledDates = [],
  children,
  onConfirm,
  onFlexibleDates,
  onOpen,
  onDismiss,
  onResetDates,
  isOpen: openProp,
  //NHCSEARCH-5363 Flex date verbiage MVT
  flexDatesLabel,
}: DatePickerProps) => {
  const { t } = useTranslation('osc-date-picker');
  const locale = useLocale({ language });
  const [open, setOpen] = React.useState(openProp);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const { setValue, reset, getValues } = useFormContext();

  const dayFieldName = `${dateFieldNamePrefix}.arrivalDate` as const;
  const endDayFieldName = `${dateFieldNamePrefix}.departureDate` as const;

  const day = useWatch({ name: dayFieldName }) as Date;
  const endDay = useWatch({ name: endDayFieldName }) as Date;

  const [initialValues, setInitialValues] = React.useState<{
    arrivalDate: Date;
    departureDate: Date;
    datesFlex: boolean;
  } | null>(null);

  const isDateOptional = !hasTodayAsDefault && !(day && endDay);
  React.useEffect(() => {
    if (!day && hasTodayAsDefault) {
      const dayDefault = startOfDay(new Date());
      const endDayDefault = isDayUse ? dayDefault : addDays(dayDefault, 1);

      setValue(dayFieldName, dayDefault, { shouldDirty: true });
      setValue(endDayFieldName, endDayDefault, { shouldDirty: true });
    }
  }, [day, dayFieldName, isDayUse, endDayFieldName, setValue, hasTodayAsDefault]);

  const dayLabel = day ? day.toLocaleDateString(locale, { dateStyle: 'long' }) : '';
  const endDayLabel = isDayUse
    ? dayLabel
    : endDay
    ? endDay.toLocaleDateString(locale, { dateStyle: 'long' })
    : '';

  const nextDay = day ? addDays(day, 1) : day;

  const formatDay = useDateFormat({ date: day, locale });
  const formatEndDay = useDateFormat({ date: endDay, locale });

  const openModal = () => {
    setOpen(true);
    setInitialValues(cloneDeep(getValues().dates));
    onOpen?.();
  };

  const dismissAndResetToOriginalDates = () => {
    setOpen(false);
    reset({ ...getValues(), dates: { ...initialValues } }, { keepDefaultValues: true });
    onDismiss?.();
  };
  const confirmAndCloseModal = () => {
    setOpen(false);
    //if user selects an arrivalDate but not departure set departureDate to arrivalDate + 1 day. TODO Revisit this once we revise these elements to have update button (or apply btn)
    if (day && !endDay) {
      setValue(endDayFieldName, isDayUse ? day : addDays(day, 1), {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
    onConfirm?.();
  };

  // MVT - Display "Reset Dates" link
  const [resetDateA11y, setResetDateA11y] = React.useState(false);
  const resetDates = () => {
    setResetDateA11y(true);
    reset(
      {
        ...getValues(),
        dates: { arrivalDate: null, departureDate: null, datesFlex: false },
      },
      { keepDefaultValues: true }
    );
    setTimeout(() => setResetDateA11y(false), 500);
    if (onResetDates) onResetDates();
  };
  const resetButtonCTA = {
    isEnabled: !hasTodayAsDefault,
    label: t('resetDates'),
    resetA11Y: resetDateA11y ? t('allSelectionsReset') : undefined,
  };

  const isClient = useIsClient();

  return (
    <div className="w-full sm:w-auto">
      {labelOptions?.whenLabel || isDateOptional ? (
        <FormLabel
          label={t('when')}
          required={labelOptions?.requiredDates}
          className="brand-ey:font-normal brand-lx:font-semibold flex pb-0.5 max-sm:w-1/4 max-sm:items-start max-sm:justify-start lg:pb-1.5"
          data-osc-product="search-dates-label"
        />
      ) : null}
      <ActionDialog
        contentDataAttribute="data-osc-date-picker"
        className="h-auto"
        title={day ? t('header') : t('addDates')}
        isOpen={open}
        onDismiss={dismissAndResetToOriginalDates}
        onCancel={dismissAndResetToOriginalDates}
        size="2xl"
        onConfirm={confirmAndCloseModal}
        onReset={resetDates}
        buttonOptions={{ reset: resetButtonCTA }}
        dialogTrigger={
          <button
            className="divide-border brand-lx:divide-primary flex w-full appearance-none justify-center divide-x rounded text-start sm:w-auto sm:gap-2 lg:gap-3 rtl:divide-x-reverse"
            data-osc-product="shop-form-dates"
            id="shop-form-dates"
            ref={buttonRef}
            type="button"
            aria-label={`${t('checkin')} ${dayLabel ? dayLabel : t('addDates')}, ${t('checkout')} ${
              endDayLabel ? endDayLabel : t('addDates')
            }`}
            onClick={openModal}
            data-testid="search-dates-button"
            // This check is necessary to fix a hydration issue
            // See for reference: https://gitlab.svc-m.hhc.hilton.com/pac/hiw/dx-ui/-/merge_requests/4994
            disabled={!isClient}
          >
            {children ? (
              children({ day: formatDay, endDay: formatEndDay })
            ) : (
              <>
                <DatePickerDatesButton day={day} language={language}>
                  <span className="brand-ou:text-primary block font-bold">{t('checkin')}</span>
                  <span className="brand-ou:text-primary block text-xs">{t('addDates')}</span>
                </DatePickerDatesButton>
                {!isDayUse ? (
                  <DatePickerDatesButton day={endDay || nextDay} language={language}>
                    <span className="brand-ou:text-primary block font-bold">{t('checkout')}</span>
                    <span className="brand-ou:text-primary block text-xs">{t('addDates')}</span>
                  </DatePickerDatesButton>
                ) : null}
              </>
            )}
          </button>
        }
      >
        <div
          data-osc-product="search-dates"
          className="border-border mx-2 border-b border-solid py-2 "
        >
          <DatePickerCalendar
            allowPrevious={allowPrevious}
            allowSameDay={allowSameDay}
            day={day}
            dayFieldName={dayFieldName}
            isDayUse={isDayUse}
            endDay={isDayUse ? day : endDay}
            endDayFieldName={endDayFieldName}
            maxDays={maxDays}
            enabledDates={enabledDates}
            dayLabel={(d, checkin) =>
              t('dayLabel', {
                date: d.toLocaleDateString(locale, { dateStyle: 'full' }),
                type: t(checkin ? 'checkin' : 'checkout'),
              })
            }
            language={language}
            MonthDateSelectorComponent={MonthDateSelectorComponent}
          />
        </div>
        {!hideFlexDates || afterCalendarContent ? (
          <div className="flex justify-between pt-4">
            {!hideFlexDates ? (
              <FormInput
                label={flexDatesLabel ?? t('flexibleDateLabel')}
                type="checkbox"
                name="dates.datesFlex"
                disabled={hasFlexDatesDisabled}
                onClick={onFlexibleDates}
              />
            ) : null}
            {afterCalendarContent ? afterCalendarContent : null}
          </div>
        ) : null}
      </ActionDialog>
    </div>
  );
};

export default DatePicker;
