import { Header } from '@dx-ui/osc-header';
import { useTranslation } from 'next-i18next';
import * as React from 'react';
import type { HeaderProps } from '@dx-ui/osc-header';
import { makeAnchorEventTarget, useSpeedbump } from '@dx-ui/osc-speedbump';
import { getLoginRedirectUrl } from '../../utils/helpers/urlBuilder';
import type { SupportedLanguage } from '@dx-ui/framework-i18n';

import { useLanguage } from '../../hooks';

import type { LoginResponse } from '@dx-ui/framework-auth-provider';
import { getGuestFirstName, useAuth } from '@dx-ui/framework-auth-provider';
import { allEnv } from '@dx-ui/framework-env';
import { useRouter } from 'next/router';

type Props = {
  forwardPageURI?: string | null;
};

const { APP_NAME } = allEnv();

export const OscMinimalHeader = (props: Props) => {
  const language = useLanguage();
  const [t] = useTranslation('header');
  const [user, setUser] = React.useState<HeaderProps['user']>();
  const { forwardPageURI } = props;
  const { locale = 'en', asPath, query } = useRouter();

  const { guestInfo, login, logout } = useAuth();
  const { onContinue, onShow } = useSpeedbump(language, APP_NAME);

  const onSignInAttempt = React.useCallback(
    async (resp: LoginResponse) => {
      if (resp?.data) {
        await login(resp);
        if (forwardPageURI) {
          const fakeEventTarget = makeAnchorEventTarget(forwardPageURI);
          if (!onShow(fakeEventTarget)) {
            onContinue();
            if (forwardPageURI) window.location.assign(forwardPageURI);
          }
        }
      }
    },
    [login, forwardPageURI, onShow, onContinue]
  );

  const onSignOut = React.useCallback(async () => {
    await logout().then(() => {
      if (asPath.includes('hgv-max')) {
        const hgvRedirectUrl = `/${locale ?? 'en'}/hgv-max/`;
        window.location.assign(hgvRedirectUrl);
      }

      if (asPath.includes('go-hilton')) {
        const goHiltonRedirectUrl = `/${locale ?? 'en'}/go-hilton/`;
        window.location.assign(goHiltonRedirectUrl);
      }

      if (asPath.includes('business')) {
        const smbLoginRedirectUrl = `/${locale ?? 'en'}/business/login/`;
        window.location.assign(smbLoginRedirectUrl);
      }

      if (/hilton-honors\/guest/.test(asPath)) {
        const redirectUrl = /guest\/points/.test(asPath)
          ? `/${locale ?? 'en'}/hilton-honors/points/`
          : getLoginRedirectUrl(asPath, query, locale as SupportedLanguage);
        window.location.assign(redirectUrl);
      }
    });
  }, [asPath, locale, logout, query]);

  React.useEffect(() => {
    setUser(
      guestInfo
        ? {
            name: getGuestFirstName({ guestInfo }),
            honorsTier: guestInfo?.hhonors?.summary?.tierName || '',
            honorsPoints: guestInfo?.hhonors?.summary?.totalPoints || 0,
            honorsPointsFmt: guestInfo?.hhonors?.summary?.totalPointsFmt || '',
            hhonorsNumber: guestInfo?.hhonors?.hhonorsNumber || undefined,
          }
        : undefined
    );
  }, [guestInfo]);

  return (
    <Header
      showNewHeader={true}
      brand={{ url: `/${language}/hilton-honors/`, name: t('hiltonHonors'), code: 'HH' }}
      isFluid={true}
      user={user}
      userLinks={{
        accountLink: undefined,
        signInLink: undefined,
        signUpLink: undefined,
      }}
      data-testid="newosc"
      onSignOut={onSignOut}
      onSignInAttempt={onSignInAttempt}
    />
  );
};
