export const ImageClickID = {
  Scroll: 'scroll',
  Img: 'img',
} as const;

export type ImageClickIDType = ValuesOf<typeof ImageClickID>;

export const ImageFunction = {
  NoValue: 'no_value',
  Modal: 'modal',
  Link: 'link',
} as const;

export type ImageFunctionType = ValuesOf<typeof ImageFunction>;

export const ImageType = {
  NoValue: 'no_value',
  Dynamic: 'dynamic',
  Carousel: 'carousel',
  Collage: 'collage',
  Full: 'full',
  Half: 'half',
  Headliner: 'headliner',
  Hero: 'hero',
  Filmstrip: 'filmstrip',
  Patchwork: 'patchwork',
} as const;

export type ImageTypeType = ValuesOf<typeof ImageType>;

// Common fields for all Image components
export type BaseImageMetrics = {
  click: {
    clickID: ImageClickIDType;
  };
  osc: {
    image: {
      itemTitle?: string;
      headline?: string;
      position?: number;
      count?: number;
      function?: ImageFunctionType;
      type?: ImageTypeType;
    };
  };
};
