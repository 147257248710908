import { useRouter } from 'next/router';

import { useAuth } from '@dx-ui/framework-auth-provider';

import {
  useSmb_MemberIdAndStatusQuery,
  useSmb_ProgramAccountMemberQuery,
} from '@dx-ui/queries-dx-guests-ui';
import { useLanguage } from './useLanguage';

import { useEffect, useState } from 'react';
import type {
  CreateProgramAccountMemberMutation,
  Smb_MemberIdAndStatusQuery,
} from '@dx-ui/queries-dx-guests-ui';
import { logError } from '@dx-ui/framework-logger';
import { generateError } from '@dx-ui/utilities-generate-error';

export function useProgramMember({
  programAccountId,
  memberData,
  shouldFetchSmbMemberIdAndStatus = false,
}: Partial<{
  programAccountId: number | null;
  memberData: CreateProgramAccountMemberMutation['createProgramAccountMember'];
  shouldFetchSmbMemberIdAndStatus: boolean;
}> = {}) {
  const { guestId, guestInfo } = useAuth();
  const language = useLanguage();
  const { query } = useRouter();
  const [isNotAssociatedWithAnyProgramAccounts, setIsNotAssociatedWithAnyProgramAccounts] =
    useState(false);
  const [isNotAssociatedWithProgramAccount, setIsNotAssociatedWithProgramAccount] = useState(false);
  const [mostRelevantProgramAccount, setMostRelevantProgramAccount] = useState<
    Smb_MemberIdAndStatusQuery['programAccounts'][number] | undefined
  >(undefined);

  const programAccountIdParam = isNaN(Number(query?.programAccountId))
    ? undefined
    : Number(query?.programAccountId);
  const accountId = programAccountId || programAccountIdParam;
  const { data, isSuccess, isLoading, error } = useSmb_MemberIdAndStatusQuery(
    { guestId: guestId as number, language },
    {
      enabled: !!guestId && (!!guestInfo?.hhonors?.isSMBMember || shouldFetchSmbMemberIdAndStatus),
    }
  );

  useEffect(() => {
    if (isSuccess && data?.programAccounts?.length === 0) {
      setIsNotAssociatedWithAnyProgramAccounts(true);
    }
  }, [isSuccess, data, setIsNotAssociatedWithAnyProgramAccounts]);

  useEffect(() => {
    if (isSuccess) {
      // TODO: Will be removed once single business logic on owner join page is deprecated. Will be handled in NHCGUEST-7737
      if (!accountId) setMostRelevantProgramAccount(data?.programAccounts?.[0]);

      if (accountId) {
        const account = data?.programAccounts?.filter(
          (programAccount) => programAccount?.accountId === accountId
        )?.[0];
        setMostRelevantProgramAccount(account);
        if (!account) setIsNotAssociatedWithProgramAccount(true);
      }
    }
  }, [
    data,
    accountId,
    mostRelevantProgramAccount,
    setIsNotAssociatedWithProgramAccount,
    isSuccess,
  ]);

  const {
    data: programAccountData,
    isLoading: memberDataLoading,
    error: programAccountDataError,
  } = useSmb_ProgramAccountMemberQuery(
    {
      guestId: guestId as number,
      language,
      input: {
        accountId: accountId || (mostRelevantProgramAccount?.accountId as number),
        memberId: mostRelevantProgramAccount?.memberId as number,
      },
    },
    {
      enabled:
        !!guestId &&
        Boolean(accountId || mostRelevantProgramAccount?.accountId) &&
        !!mostRelevantProgramAccount?.memberId,
    }
  );

  if (memberData)
    return {
      accountId,
      ...memberData.data,
      guestId,
      isNotAssociatedWithAnyProgramAccounts: false,
      isNotAssociatedWithProgramAccount: false,
      memberDataLoading: false,
      isLoading: false,
      accountName: mostRelevantProgramAccount?.accountName,
      accountStatus: mostRelevantProgramAccount?.accountStatus,
      memberStatus: mostRelevantProgramAccount?.memberStatus,
    };

  if (error) {
    logError(
      'USE_PROGRAM_MEMBER',
      generateError(error),
      `Error on ${useSmb_MemberIdAndStatusQuery.name}`
    );
  }

  if (programAccountDataError) {
    logError(
      'USE_PROGRAM_MEMBER',
      generateError(programAccountDataError),
      `Error on ${useSmb_ProgramAccountMemberQuery.name}`
    );
  }

  const member = {
    accountId,
    ...programAccountData?.programAccountMember,
    isNotAssociatedWithAnyProgramAccounts,
    isNotAssociatedWithProgramAccount,
    memberDataLoading,
    isLoading,
    accountName: mostRelevantProgramAccount?.accountName,
    accountStatus: mostRelevantProgramAccount?.accountStatus,
    memberStatus: mostRelevantProgramAccount?.memberStatus,
  };
  return member;
}
