import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { Link } from '@dx-ui/osc-link';
import { Popup } from '@dx-ui/osc-popup';
import { LoginIFrame } from '@dx-ui/osc-login';
import type { MegaMenuProps } from '../types';
import {
  sendInteractionReward,
  trackNavClickBasedOnLabel,
  trackNavClick,
} from '../header.utilities';
import { LanguageSelector } from '@dx-ui/osc-language-selector';
import { sendReward } from '@dx-ui/framework-conductrics';
import { Logo } from '../header.logo';
import { useRef } from 'react';
import { NavItemWithLink, NavItemWithModal, NavItemWithPopup } from './nav-items';
import UserPopupContents, { NavigationPopupContents } from './popup-contents';

export function MegaMenu({
  isFluid,
  suppressLogo = false,
  shouldSuppressHonorsAccountLinks,
  shouldSuppressFindStay,
  shouldSuppressJoinAndSignIn,
  shouldOpenAccountLinksInNewWindow,
  brand,
  mainNavLinks,
  user,
  languageSelectorOptions,
  loginOptions,
  onSignInAttempt,
  userLinks,
  onSignOut,
  heading,
  theme,
  SignUpComponent,
}: MegaMenuProps) {
  const userNavProps = {
    user,
    userLinks,
    onSignInAttempt,
    onSignOut,
    loginOptions,
    languageSelectorOptions,
    theme,
    shouldSuppressHonorsAccountLinks,
    shouldSuppressFindStay,
    shouldSuppressJoinAndSignIn,
    shouldOpenAccountLinksInNewWindow,
    SignUpComponent,
  };
  const isDark = theme === 'dark';
  const headingLevelProps = heading
    ? {
        role: 'heading',
        'aria-level': 1,
        'aria-label': heading,
      }
    : {};

  return (
    <div className="bg-transparent">
      <div
        className={cx({
          'container-fluid': isFluid,
          container: !isFluid,
        })}
      >
        <div className="relative flex flex-wrap">
          {suppressLogo ? null : (
            <div className="flex min-h-[4.5rem] items-center" {...headingLevelProps}>
              <Link
                url={brand.url}
                target={brand.target}
                onClick={sendInteractionReward}
                // once nav is implemented on all pages for OHW make w-72 for all brands per UX/UI
                anchorClassName="relative block h-14 w-28 brand-ou:lg:w-72 brand-lx:lg:w-72 me-1"
                showNewWindowIcon={false}
              >
                <Logo theme={theme} brand={brand} />
              </Link>
            </div>
          )}
          <div className="absolute end-0 top-0">
            <UserNav {...userNavProps} />
          </div>
        </div>
      </div>
      <div
        className={cx({
          'nav-bottom-link-area': !isDark,
          'nav-bottom-link-area-dark': isDark,
        })}
      >
        <div
          className={cx({
            'container-fluid': isFluid,
            container: !isFluid,
          })}
        >
          <div className="flex overflow-auto" data-testid="header-links-container">
            {mainNavLinks?.length ? <MainNav theme={theme} mainNavLinks={mainNavLinks} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

type UserNavProps = Pick<
  MegaMenuProps,
  | 'loginOptions'
  | 'onSignInAttempt'
  | 'onSignOut'
  | 'user'
  | 'userLinks'
  | 'languageSelectorOptions'
  | 'theme'
  | 'shouldSuppressHonorsAccountLinks'
  | 'shouldSuppressFindStay'
  | 'shouldSuppressJoinAndSignIn'
  | 'shouldOpenAccountLinksInNewWindow'
  | 'SignUpComponent'
>;

function UserNav({
  loginOptions,
  onSignInAttempt,
  onSignOut,
  user,
  languageSelectorOptions,
  userLinks,
  theme,
  shouldSuppressHonorsAccountLinks,
  shouldSuppressFindStay,
  shouldSuppressJoinAndSignIn,
  shouldOpenAccountLinksInNewWindow,
  SignUpComponent,
}: UserNavProps) {
  const [t] = useTranslation('osc-header');

  const defaultOptions: React.ComponentProps<typeof LoginIFrame> = {
    frameSrc: userLinks?.signInLink?.url || '',
    title: t('signIn'),
    onLoginAttempt: onSignInAttempt,
  };

  const userButtonLinkRef = useRef<HTMLButtonElement>(null);

  const isDark = theme === 'dark';

  return (
    <nav className="relative ms-auto flex min-h-[4.5rem]" aria-label={t('hiltonHonors')}>
      <ul className="flex">
        {languageSelectorOptions ? (
          <li className="flex items-center pb-2 pe-2 pt-1">
            <LanguageSelector theme={theme} {...languageSelectorOptions} />
          </li>
        ) : null}
        {userLinks?.findStayLink && !shouldSuppressFindStay ? (
          <NavItemWithLink
            onClick={() => {
              sendInteractionReward();
              sendReward('find-stay-click');
              trackNavClick('gh_findstay');
            }}
            url={userLinks?.findStayLink.url}
            isNewWindow={shouldOpenAccountLinksInNewWindow}
            showNewWindowIcon={false}
            theme={theme}
            section="user"
            isWide
            namedIcon="calendar"
            label={t('findStay')}
          />
        ) : null}
        {user ? (
          <Popup>
            <NavItemWithPopup
              section="user"
              theme={theme}
              popupButtonRef={userButtonLinkRef}
              onClick={() => {
                trackNavClick('gh_membername');
                sendReward('profile-name-click');
              }}
              label={t('greetings', { username: user.name })}
              icon="user-circle"
            >
              <UserPopupContents
                user={user}
                userLinks={userLinks}
                shouldOpenAccountLinksInNewWindow={shouldOpenAccountLinksInNewWindow}
                shouldSuppressHonorsAccountLinks={shouldSuppressHonorsAccountLinks}
                onSignOut={onSignOut}
              />
            </NavItemWithPopup>
          </Popup>
        ) : (userLinks?.signInLink || userLinks?.signUpLink || SignUpComponent) &&
          !shouldSuppressJoinAndSignIn ? (
          <>
            {userLinks?.signUpLink || SignUpComponent ? (
              SignUpComponent ? (
                <SignUpComponent
                  loggedInButtonRef={userButtonLinkRef}
                  className="nav-item-text mx-1"
                >
                  {t('signUp')}
                </SignUpComponent>
              ) : userLinks?.signUpLink ? (
                <NavItemWithLink
                  section="user"
                  {...userLinks.signUpLink}
                  underline={false}
                  showNewWindowIcon={false}
                  label={t('signUp')}
                  theme={theme}
                  onClick={() => {
                    sendInteractionReward();
                    sendReward('nav-join-click');
                    trackNavClick('gh_join_signin');
                  }}
                />
              ) : null
            ) : null}

            {(userLinks?.signUpLink || userLinks?.signUpComponent) && userLinks?.signInLink ? (
              <div
                aria-hidden={true}
                className="nav-list-divider-container group flex h-full items-center pt-1 text-sm"
              >
                <div className={isDark ? 'nav-list-divider-dark' : 'nav-list-divider'} />
              </div>
            ) : null}

            {userLinks?.signInLink ? (
              <NavItemWithModal
                section="user"
                theme={theme}
                onLoginAttempt={defaultOptions.onLoginAttempt}
                title={defaultOptions.title}
                onClick={() => {
                  trackNavClick('gh_join_signin');
                  sendReward('nav-sign-in-click');
                }}
                buttonIcon="user-circle"
                buttonLabel={t('signIn')}
              >
                <LoginIFrame data-e2e="loginIframe" {...defaultOptions} {...loginOptions} />
              </NavItemWithModal>
            ) : null}
          </>
        ) : null}
      </ul>
    </nav>
  );
}

type MainNavProps = Required<Pick<MegaMenuProps, 'mainNavLinks'>> & {
  theme?: Exclude<CmsBrandComponentTheme, 'light'>;
};

function MainNav({ mainNavLinks, theme }: MainNavProps) {
  return (
    <div className="flex min-h-[4.5rem] items-center">
      <div className="size-full overflow-x-visible">
        <ul className="flex h-full items-center rtl:space-x-reverse">
          {mainNavLinks.map(({ label, subMenu, link, onClick }) => {
            if (!subMenu?.length && link?.url) {
              return (
                <NavItemWithLink
                  {...link}
                  section="navigation"
                  label={label}
                  isWide
                  key={label}
                  theme={theme}
                  onClick={() => {
                    sendInteractionReward();
                    trackNavClickBasedOnLabel(label);
                    onClick?.();
                  }}
                />
              );
            }
            if (subMenu?.length) {
              return (
                <Popup key={label}>
                  <NavItemWithPopup
                    section="navigation"
                    label={label}
                    indicator="arrowhead-small-up"
                    rotateOnOpen
                    theme={theme}
                    onClick={() => {
                      trackNavClickBasedOnLabel(label);
                    }}
                    experimentationConfiguration={link?.experimentationConfiguration}
                  >
                    <NavigationPopupContents subMenu={subMenu} label={label} />
                  </NavItemWithPopup>
                </Popup>
              );
            }
            return null;
          })}
        </ul>
      </div>
    </div>
  );
}

export default MegaMenu;
